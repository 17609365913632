import { initDialog, openDialog } from './common/dialog';

document.addEventListener('DOMContentLoaded', function () {
  if (!localStorage.getItem('discountPopup') && $('.discount-popup').length > 0) {
    const $popupImg = $('.discount-popup').find('.discount-popup-body img');
    const images = $popupImg.toArray();

    const checkImagesLoaded = () => {
      let allLoaded = true;
      images.forEach(img => {
        if (!img.complete || img.naturalWidth === 0) {
          allLoaded = false;
        }
      });
      return allLoaded;
    };

    if (checkImagesLoaded()) {
      openPopup();
    } else {
      images.forEach(img => {
        $(img).on('load', function () {
          if (checkImagesLoaded()) {
            openPopup();
          }
        });
      });
    }
  }

  function openPopup() {
    const discountPopup = initDialog('discount-popup');
    openDialog(discountPopup);
    localStorage.setItem('discountPopup', true);
  }
});
