import { initDialog, openDialog, closeDialog } from './common/dialog';

function isIso(iso) {
  return iso.length == 2 ? true : false;
}

function isEmail() {
  var email = $('.notify-form input[name="notify_mail"]').val();
  var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function isObligatoryInputsChecked(dialogSelector) {
  return (
    $(`${dialogSelector} input[name="vasco-terms"]`).is(':checked') &&
    $(`${dialogSelector} input[name="vasco-privacy"]`).is(':checked') &&
    $(`${dialogSelector} input[name="newsletter"]`).is(':checked')
  );
}

function toggleNotifyButton(dialogSelector) {
  isEmail() && isObligatoryInputsChecked(dialogSelector)
    ? $('#notify_save').prop('disabled', false)
    : $('#notify_save').prop('disabled', true);
}

function setupCheckboxHandler(dialogSelector) {
  $(document).on('click change keydown', `${dialogSelector} input[type="checkbox"]`, function () {
    toggleNotifyButton(dialogSelector);

    if (!$(`${dialogSelector} .termsandprivacy-formfields .form-terms input[type=checkbox]`).not(':checked').length) {
      $(`${dialogSelector} .termsandprivacy-formfields input[name=select-all]`).prop('checked', true);
    } else {
      $(`${dialogSelector} .termsandprivacy-formfields input[name=select-all]`).prop('checked', false);
    }
  });
}

function handleMailInputCheck(dialogSelector) {
  $(`${dialogSelector} #notify_mail`).on('input', function () {
    toggleNotifyButton(dialogSelector);
  });
}

$('form#notify-form').on('submit', function (e) {
  e.preventDefault();

  var email = $('.notify-form input[name="notify_mail"]').val();
  var marketing = $('.notify-form input[name="newsletter"]').is(':checked') ? 1 : 0;
  var idAttribute = 0;
  let idProduct = 0;

  if ($('body#product').length > 0) {
    idProduct = parseInt($("#add-to-cart-or-refresh input[name='id_product']").val());
  }

  if ($('body#category').length > 0) {
    idProduct = $('#notify_save').data('id-product');
  }

  if ($('body#product .product-variants-list').length > 0) {
    idAttribute = parseInt($('.product-variants .input-color:checked').val());
  }
  if ($('body#category .product-variants').length > 0) {
    idAttribute = $('#notify_save').data('id-attribute');
  }

  var isoLang = $(".notify-form input[name='lang_iso']").val();
  var idShop = parseInt($(".notify-form input[name='shop_id']").val());

  if (isEmail() && isIso(isoLang)) {
    var requestData = {
      action: 'addEmail',
      notifyData: {
        notify_email: email,
        newsletter: marketing,
        product_id: idProduct,
        attribute_id: idAttribute,
        lang_iso: isoLang,
        shop_id: idShop,
      },
    };
    postNotifyme(requestData);
  }
});

$(document).on('click', '.notify-modal-button', function (e) {
  e.preventDefault();
  if ($('body#category').length) {
    $('#notify_save').attr('data-id-product', $(this).attr('data-id-product'));
    $('#notify_save').attr('data-id-attribute', $(this).attr('data-id-attribute'));
  }

  const selectorDialog = initDialog('notify-dialog');
  openDialog(selectorDialog);
});

$(document).on('click', '.notify-me-button', function (e) {
  e.preventDefault();
  let newsletterMail = $('.notify-me-bottom input[type=email]').val();
  if (newsletterMail) {
    $('.notify-form input[type=email]').val(newsletterMail);
  }

  const selectorDialog = initDialog('notify-dialog-landing');
  openDialog(selectorDialog);
});

$(document).on('click', '.notify-dialog-landing .basic-dialog-body .notify-form .btn-wrapper', function (e) {
  e.preventDefault();
  var email = $('.notify-form input[name="notify_mail"]').val();
  var landingname = $('body').attr('id');
  var country = $('html').attr('lang');

  addNotify(email, landingname, country);
});

function postNotifyme(requestData) {
  if (notifyme_controller) {
    $.post(notifyme_controller, requestData)
      .then(function (resp) {
        if (resp.message) {
          console.log(resp);
          $('.notify-form .alert').show();
          setTimeout(function () {
            $('.notify-alert').fadeOut(300);
          }, 1000);

          setTimeout(function () {
            const selectorDialog = initDialog('notify-dialog');
            closeDialog(selectorDialog);
          }, 1000);
        }
      })
      .fail(function (resp) {
        console.log(resp);
      });
  }
}

function addNotify(email, landingname, country) {
  if (e1landing_controller) {
    $.post(e1landing_controller, {
      ajax: true,
      action: 'AddNotifyLanding',
      e_mail: email,
      landing_name: landingname,
      country_iso: country,
    })
      .then(function (data) {
        console.log(data);
        $('.notify-alert').show();
        setTimeout(function () {
          $('.notify-alert').fadeOut(300);
        }, 1000);

        setTimeout(function () {
          const selectorDialog = initDialog('notify-dialog-landing');
          closeDialog(selectorDialog);
        }, 1000);
      })
      .catch(function (err) {
        console.error('error:', err);
      });
  }
}

const e1landing_controller = typeof window.e1landing_url !== 'undefined' ? window.e1landing_url : null;
const notifyme_controller =
  typeof window.notifymeFrontController !== 'undefined' ? window.notifymeFrontController : null;

setupCheckboxHandler('#notify-dialog');
setupCheckboxHandler('#notify-dialog-landing');
handleMailInputCheck('#notify-dialog');
handleMailInputCheck('#notify-dialog-landing');
